<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        {{$isExamSearch()?'报名查询':'考试报名'}}
      </template>
      <template #input>
        <a-date-picker placeholder="请选择考试日期" @change="onDataChange" />
        <a-select placeholder="请选择考试状态" v-model="queryParams.examStatus"  allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">未开始</a-select-option>
          <a-select-option :value="2">进行中</a-select-option>
          <a-select-option :value="3">已结束</a-select-option>
        </a-select>
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch">搜索</a-button>
        <a-button class="all_boder_btn" @click="addPage()" v-if="!$isExamSearch()" v-hasPermi="['exam:signup:add']">新建</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="" @change="callback">
        <a-tab-pane v-for="(item) in tabData" :key="item.configId" :tab="item.abbreviation"></a-tab-pane>
      </a-tabs>
      <a-table
      class="table-template"
      :loading="loading"
      :rowKey="item=>item.timeId"
      :columns="columns"
      :scroll="{ x: 1200 }"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.current,  
        defaultPageSize:queryParams.size, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.current - 1) * 10 + i + 1 }}
          </div>
        </template>

        <template slot="examStatus" slot-scope="text">
          <span class="state_all" :class="text=='未开始'?'state_color_red':text=='进行中'?'state_color_green':''">{{text}}</span>
        </template>

        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <router-link to @click.native="onSignUpExamine(item)">{{$isExamSearch()?'考生详情':'报名审核'}}</router-link>
            <span v-if="!$isExamSearch()" v-hasPermi="['exam:signup:update']">|</span>
            <router-link v-if="!$isExamSearch()" to @click.native="isNumberShow=true,signupNum = item.signupNum, timeId = item.timeId" v-hasPermi="['exam:signup:update']">修改考试名额</router-link>
            <span v-if="!$isExamSearch()" v-hasPermi="['exam:signup:edit']">|</span>
            <router-link v-if="!$isExamSearch()" to @click.native="addPage(item.signupId)" v-hasPermi="['exam:signup:edit']">编辑</router-link>
            <span v-if="!$isExamSearch()" v-hasPermi="['exam:signup:delete']">|</span>
            <router-link v-if="!$isExamSearch()" to @click.native="deleteData(item)" v-hasPermi="['exam:signup:delete']">删除</router-link>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 修改考试名额 -->
    <a-modal v-model="isNumberShow" align="center"  title="修改考试名额" >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">考试名额：</span>
          <a-input class="right" v-model="modifySignNum" placeholder="请输入变更后的名额(限数字)" oninput="value=value.replace(/[^0-9.]/g,'')" />
        </div>
        <div class="line-item">
          <span class="all_left_name">原考试名额：</span>
          <a-input class="right" :value="signupNum" :disabled='true'/>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onModify">确认</a-button>
        <a-button  @click="isNumberShow=false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "考试日期",
    align:'center',
    dataIndex: "examDate"
  },
  {
    title: "考试场次",
    align:'center',
    dataIndex: "examDateTime"
  },
  {
    title: "考试名额",
    align:'center',
    dataIndex: "signupNum"
  },
  {
    title: "报名人数",
    align:'center',
    dataIndex: "signupTotal"
  },
  {
    title: "考生人数",
    align:'center',
    dataIndex: "examinneNum"
  },
  {
    title: "未审核人数",
    align:'center',
    dataIndex: "reviewedNum"
  },
  {
    title: "已驳回人数",
    align:'center',
    dataIndex: "rejectNum"
  },
  {
    title: "考试状态",
    align:'center',
    dataIndex: "examStatus",
    scopedSlots: { customRender: "examStatus" }

  },
  {
    title: "操作",
    fixed: "right",
    align:'center',
    width: "200px",
    scopedSlots: { customRender: "operation" }
  }
];
import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns, // 表头
      tableData:[], // 列表数据
      tabData:[], // tab列表
      tabName:'', // tab名称
      loading:false, // 列表加载
      total: 0, // 总数量
      queryParams:{
        configId: 0,  //tabID,
        current: 1, // 页码
        size: 10, // 页数
        examDate:'', // 考试日期
        examStatus:undefined // 考试状态
      },

      modifySignNum:'',  // 变更后的考试名额
      signupNum:'',  // 考试名额
      timeId:'', // 场次id
      isNumberShow:false,  // 修改考试名额
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.current = e.current
      this.queryParams.size = e.pageSize
      this.getManageList()
    },
    // tab切换
    callback(e){
      this.queryParams.current = 1
      this.tabData.forEach(element => {
        if(element.configId == e){
          this.tabName = element.abbreviation
        }
      })
      this.queryParams.configId = e
      this.getManageList()
    },
    onDataChange(e,v){
      this.queryParams.examDate = v
    },
    // 高级检索
    onSearch(){
      this.queryParams.current = 1
      this.getManageList()
    },

    addPage(id){
      this.$router.push({ path: "/admin/Examination/AddSignUp", query: { configId: this.queryParams.configId,id:id } })
    },
    // 报名审核
    onSignUpExamine(e){
      localStorage.setItem('signUpParamsObj', '')
      this.$router.push({ path: this.$isExamSearch()?"/admin/ExamSearch/RegistrationReview":"/admin/Examination/RegistrationReview",query: { signupId:e.signupId, timeId:e.timeId, examName:this.tabName, time:e.examDateTime } })
    },
    // 查询tab列表
    getTabList(){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/config/exam/tab/list",
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.tabData = res.data
          this.queryParams.configId = res.data[0].configId
          this.tabName = res.data[0].abbreviation
          this.getManageList()
        } else {
          this.tabData = []
        }
      })
    },
    // 删除标签
    deleteData(e) {
      let _this = this
      this.$confirm({
        title: '确定删除该数据吗?',
        okText: '确认',
        onOk() {
          _this.$ajax({
            url: '/hxclass-management/exam/signup/del?signupId=' + e.signupId + '&timeId=' + e.timeId,
            method: "DELETE",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.getManageList()
            } else {
              _this.$message.warning(res.message);
            }
          })
        },
        onCancel() {},
      });
    },
    // 修改名额
    onModify(){
      if(!this.modifySignNum){
        this.$message.warning('请输入考试名额');
        return
      }
      this.$ajax({
        method: 'PUT',
        url:"/hxclass-management/exam/signup/edit/time",
        params: {
          signupNum: this.modifySignNum,
          timeId: this.timeId
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.isNumberShow = false
          this.modifySignNum = ''
          this.$message.success('修改成功');
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/signup/list",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.message);
        }
      })
    },
    
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getTabList()
  },
  // 生命周期-实例挂载后调用
  mounted () {
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    isNumberShow(){
      if(!this.isNumberShow){
        this.modifySignNum = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.all_content_box{
  .line-item{
    margin-bottom: 20px;
    display: flex;
    .all_left_name{
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .line-item:last-child{
    margin-bottom: 0;
  }
  .right{
    flex: 1;
    height: 40px;
    /deep/.ant-input{
      height: 40px;
    }
  }
  textarea.ant-input{
    flex: 1;
  }
}
.table-template{
  margin-top: 0;
}
/deep/ .ant-modal-footer{
  text-align: center;
}
</style>
